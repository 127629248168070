.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;
}


.page-content {
  & > * {
    margin: auto;
  }

  .page-titles {
    margin-top: var(--app-large-margin);
    text-align: center;
  }

  .card {
    max-width: 600px;
    @media only screen and (max-width: 620px) {
      margin: var(--app-large-margin) 10px 0;
    }
    @media only screen and (min-width: 620px) {
      margin: var(--app-large-margin) auto;
    }
  }

  &__header-with-logo {
    .toolbar {
      --min-height: 70px;
    }

    .logo {
      max-height: 50px;
    }

    .page-title {
      font-weight: 100;
    }
  }
}

.top-menu {
  height: 64px;

  .menu-list {
    list-style-type: none;

    .logo {
      cursor: pointer;
      margin-right: 24px;
      position: relative;

      img {
        height: 38px;
        display: block;
      }

      .version,
      .backoffice {
        position: absolute;
      }

      .version {
        right: 0;
        bottom: 0;
        font-size: 10px;
        color: var(--ion-color-medium);
      }

      .backoffice {
        font-weight: bold;
        left: 34px;
        bottom: -1px;
        font-size: 12px;
        color: var(--ion-color-dark);
      }
    }

    ion-button {
      ion-icon {
        font-size: 18px;
        vertical-align: middle;
      }
    }


    li {
      height: 48px;
      float: left;

      ion-item {
        --border-color: transparent;
        --border-with: 0;
      }
    }
  }
}
