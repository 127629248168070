$column-footer-height: 36;
$column-header-height: $column-footer-height;
$structure-border-color: var(--ion-color-light-shade);

.drag-preview {
  min-width: 150px;
  background-color: #62bdbc60 !important;
}

.structure-table {
  height: 100%;
  display: flex;

  .cdk-drag-placeholder {
    display: none;
  }

  .structure-table-row {
    border-right: 1px solid #{$structure-border-color};
    vertical-align: top;
    position: relative;

    & > * {
      display: flex;
      flex-direction: column;
      height: 100%;

      .header {
        border-bottom: 1px solid #{$structure-border-color};
        background-color: var(--ion-color-dark-contrast);
        height: #{$column-header-height}px;
        min-height: #{$column-header-height}px;
        text-align: center;
        line-height: #{$column-header-height}px;
        color: var(--ion-color-medium);

        .sort-form {
          display: flex;
          align-items: center;
          padding: 0;
          justify-content: space-around;
          height: 100%;

          & > ion-button {
            flex-grow: 1;
            margin: 0;
            height: #{$column-header-height}px;
            --padding-start: 5px;
            --padding-end: 5px;

            ion-icon {
              margin-right: 5px;
            }
          }
        }
      }

      .column-container-with-header {
        overflow-y: scroll;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .footer {
        border-top: 1px solid #{$structure-border-color};
        background-color: var(--ion-color-dark-contrast);

        &:hover {
          .footer-add-choice {
            display: block !important;
          }
        }

        ion-button {
          margin: 0;

          ion-icon {
            margin-right: 10px;
          }
        }

        .footer-add-choice {
          display: none;
          position: absolute;
          left: 10px;
          right: 20px;
          bottom: #{$column-footer-height}px;
          background: var(--ion-color-dark-contrast);
          border: 1px solid #{$structure-border-color};
          box-shadow: 0px -1px 5px var(--ion-color-medium);
        }
      }
    }

    .empty-column {
      text-align: center;
      padding-top: 50px;
      color: var(--ion-color-medium);
      font-size: 20px;
    }
  }

  .column-spinner {
    text-align: center;
    padding: 8px 5px 8px 10px;
    font-size: 14px;
  }

  .striped_even {
    background-color: var(--ion-color-light-2);
  }
}

.no-dragging-mode {
  .hovered {
    .column-component {
      background-color: var(--ion-color-light-shade);
    }
  }

  .footer:not(.disabled) {

    &:hover {
      .footer-add-choice {
        display: block !important;
      }
    }
  }
}
