.little-modal {
  .modal-wrapper {
    height: 60%;
    width: 80%;
  }
}

.big-modal {
  --min-height: 90%;
  --min-width: 90%;
}

.full-modal {
  --min-height: 100%;
  --min-width: 100%;
}

.modal-with-body-padding {
  ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-bottom: 10x;
    --padding-top: 10px;
  }
}

.small-modal {
  --height: 200px;
}

.instruction-review-modal {
  --min-height: 90%;
  --max-width: 100%;
  --width: 900px;

}

.modal-second-floor {
  z-index: 100000 !important;
}

.prompt {
  z-index: 100010 !important;
}

ion-toast {
  z-index: 110000 !important;
}

.picture-details-modal {
  --height: auto;
  --width: auto;
  --max-height: 100%;
  --overflow: auto;
}


.search-bar-more-options-modals {
  --max-height: 80%;
  --overflow: auto;
}

