/* Form */
form {
  padding: 10px 20px 10px;

  &:not(.was-submitted) .ion-invalid {
    --highlight-background: transparent;
  }


  &.structured-form {
    padding: 0;

    fieldset {
      position: relative;
      margin: 20px 0 10px;
      padding: 10px;
      padding-bottom: 0;
      color: var(--ion-color-medium);

      legend {
        text-align: center;
        font-size: 20px;
      }

      & > ion-item {
        --min-height: 0;
        margin: 5px 0;
        //border: 1px solid #cdcdcd;
      }
    }
  }

  color-picker {
    .color-picker {
      --padding-start: 4px !important;
      --padding-end: 4px !important;
      border: 1px solid var(--ion-color-light-shade);
      border-radius: 4px;
    }
  }

}

fieldset {
  border: 0;
  padding: 0;
}

.validation-message {
  color: var(--ion-color-danger);
  padding: 0 0 4px 4px;
  font-size: 12px;
}

ion-input {
  input {
    height: 100%;
  }
}

ion-input.input-uppercase {
  input {
    text-transform: uppercase !important;
  }
}


.legacy-textarea.sc-ion-textarea-ios-h .native-textarea[disabled].sc-ion-textarea-ios,
.legacy-input.sc-ion-input-ios-h .native-input[disabled].sc-ion-input-ios,
.textarea-disabled.sc-ion-textarea-ios-h,
.display-info.disabled,
.no-illustration-container.disabled {
  opacity: 0.4 !important;
}

.description {
  margin: 0;
  font-size: 12px;
}
