
.ugly-fix-for-datatable {

  & > * {
    height: 100%;
  }

  .datatable-footer-inner {
    justify-content: space-between;
  }

  datatable-body {
    height: calc(100% - 100px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

:host
::ng-deep
.ngx-datatable.material:not(.cell-selection)
.datatable-body-row:hover {
  background-color: #007bff2e;
}
