$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts/icomoon" !default;

$caplock-icon-factory: "\e900";
$caplock-icon-upload: "\e961";
$caplock-icon-flag: "\e9cc";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fd7dro');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fd7dro#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fd7dro') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fd7dro') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fd7dro##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="caplock-icon-"], [class*=" caplock-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.caplock-icon-equipment:before {
  content: "\e909";
}

.caplock-icon-equipment-open:before {
  content: "\e908";
}
.caplock-icon-organ:before {
  content: "\e901";
}
.caplock-icon-organ_of_neutralisation:before {
  content: "\e900";
}
.caplock-icon-mobile-network:before {
  content: "\e90a";
}
.caplock-icon-offline:before {
  content: "\e90b";
}
.caplock-icon-wifi:before {
  content: "\e90c";
}
.caplock-icon-drag:before {
  content: "\e90d";
}
.caplock-icon-factory:before {
  content: "\e90e";
}
.caplock-icon-securing_instruction:before {
  content: "\e903";
}
.caplock-icon-consignment_instruction:before {
  content: "\e905";
}
.caplock-icon-folder-open:before {
  content: "\e906";
}
.caplock-icon-folder:before {
  content: "\e907";
}
.caplock-icon-instruction:before {
  content: "\e904";
}
.caplock-icon-organ_of_separation:before {
  content: "\e902";
}
.caplock-icon-folder:before {
  content: "\e907";
}
.caplock-icon-upload:before {
  content: "\e961";
}
.caplock-icon-search:before {
  content: "\e986";
}
.caplock-icon-folder-tree:before {
  content: "\e9bc";
}
.caplock-icon-flag:before {
  content: "\e9cc";
}
.caplock-icon-sort-asc:before {
  content: "\ea48";
}
.caplock-icon-sort-desc:before {
  content: "\ea49";
}
.caplock-icon-sort:before {
  content: "\ea4c";
}
.caplock-icon-file-pdf:before {
  content: "\eadf";
}

.caplock-icon-file-excel:before {
  content: "\eae2";
}
