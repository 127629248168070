:root {
  --app-fair-margin: 2rem;
  --app-large-margin: 4rem;
}

@import "./form";
@import "./theme";
@import "./typography";
@import "./icomoon";
@import "./button";
@import "./modal";
@import "./page";
@import "./datatable";
@import "./sso";
@import "./cursor";
@import "./popover_factories";
@import "./tree";
@import "./table";
@import "./input";
@import "./title";
@import "./translate";
@import "./popover";
@import "./factory-structure";

.primary-color {
  --color: var(--ion-color-primary);
  color: var(--ion-color-primary) !important;
}

.danger-color {
  --color: var(--ion-color-danger);
  color: var(--ion-color-danger) !important;
}

.hidden {
  display: none !important;
}
