// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #0d9dca;
  --ion-color-primary-rgb: 13, 157, 202;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0a85ab;
  --ion-color-primary-tint: #0eb1e3;

  /** secondary **/
  --ion-color-secondary: #62bdbc;
  --ion-color-secondary-rgb: 98, 189, 188;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4f9c9b;
  --ion-color-secondary-tint: #6fd9d7;

  /** tertiary **/
  --ion-color-tertiary: #0d8845;
  --ion-color-tertiary-rgb: 13, 136, 69;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #096332;
  --ion-color-tertiary-tint: #10ad57;

  /** success **/
  --ion-color-success: #8db724;
  --ion-color-success-rgb: 141, 183, 36;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #72941c;
  --ion-color-success-tint: #a3d428;

  /** warning **/
  --ion-color-warning: orange;
  --ion-color-warning-rgb: 253, 194, 11;
  --ion-color-warning-contrast: orange;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: orange;
  --ion-color-warning-tint: orange;

  /** danger **/
  --ion-color-danger: #e62736;
  --ion-color-danger-rgb: 230, 39, 54;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c41f2c;
  --ion-color-danger-tint: #fc3f4e;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #8e7f7c;
  --ion-color-medium-rgb: 142, 127, 124;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6b5f5d;
  --ion-color-medium-tint: #ab9895;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-2: #eaebeb;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Font-family **/
  --ion-font-family: "open-sans";
}
