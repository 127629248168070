.sso-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
  flex-wrap: wrap;

  & > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .google-button {
    width: 191px;
    height: 46px;
    cursor: pointer;
    background-image: url('./assets/icon/google/btn_google_signin_dark_normal_web.png');

    &:hover {
      background-image: url('./assets/icon/google/btn_google_signin_dark_focus_web.png');
    }

    &:focus {
      background-image: url('./assets/icon/google/btn_google_signin_dark_pressed_web.png');
    }
  }

  .microsoft-button {
    width: 215px;
    height: 41px;
    cursor: pointer;
    background-image: url('./assets/icon/microsoft/ms-symbollockup_signin_light.png');
  }
}