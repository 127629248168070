ion-button {
  text-transform: none;

  ion-spinner {
    margin-right: 10px;
  }
}

.submit-button {
  margin: var(--app-fair-margin) 0 0;
}

.picture-modal-for-organ-illustration {
  --width: 850px;
}